<template>
  <b-row class="align-items-center justify-content-center">
    <b-col cols="12" md="7">
      <div class="container">
        <div class="chat-container">
          <div class="chat-header border-bottom justify-content-between">
            <div class="d-flex align-items-center">
              <img
                :src="
                  detailData.user && detailData.user.avatar
                    ? detailData.user.avatar
                    : require(`@/assets/images/avatars/null-profile.webp`)
                "
                alt="User Avatar"
              />
              <div class="d-flex flex-column">
                <h4>{{ detailData.user ? detailData.user.name : "-" }}</h4>
                <p class="mb-0">
                  Topik:
                  <b>{{ detailData.topic ? detailData.topic.title : "-" }}</b>
                </p>
              </div>
            </div>
            <div
              class="text-primary"
              v-b-modal.form-report
              v-if="detailData.status === 'ongoing'"
            >
              <feather-icon icon="FlagIcon" class="custom-class" size="1.5x" />
            </div>
            <b-modal
              id="form-report"
              :centered="!isDesktop"
              no-close-on-backdrop
              class="text-center"
              size="sm"
              title="Report"
            >
              <div class="form-group">
                <label for="group"
                  >Berikan alasan mengapa kamu mereport ?</label
                >
                <v-select
                  id="group"
                  label="description"
                  v-model="newReport"
                  :options="[
                    'Konten merupakan spam',
                    'Konten mengandung unsur pornografi',
                    'Konten mengandung kebencian atau SARA',
                    'Perundungan (bullying) atau penghinaan',
                    'Konten merupakan informasi palsu (hoax)',
                    'Konten mengandung kekerasan',
                    'Konten merupakan penipuan',
                    'Konten mengandung pelanggaran HAKI',
                    'Konten menjual barang ilegal atau dilarang pemerintah',
                    'Konten mengandung unsur menyakiti diri sendiri',
                    'Konten mengandung unsur gangguan perilaku makan (eating disorder)',
                    'Konten mengandung unsur obat terlarang',
                    'Kebocoran data pribadi saya atau orang lain',
                    'Lainnya',
                  ]"
                  placeholder="-- Pilih --"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </div>
              <template #modal-footer="{}">
                <b-button
                  variant="primary"
                  @click="sendReport()"
                  v-bind:disabled="isLoading"
                >
                  Submit
                </b-button>
              </template>
            </b-modal>
          </div>
          <div class="chat-messages-box" ref="messagesContainer">
            <infinite-loading direction="top" @infinite="getData">
              <div slot="no-more"></div>
              <!-- <div slot="no-results">Tidak ada percakapan</div> -->
            </infinite-loading>
            <div v-for="chats in chatData" :key="chats.uuid">
              <div
                class="chat-message"
                :class="
                  chats.sender.uuid === detailData.mentor.uuid
                    ? 'chat-user'
                    : ''
                "
              >
                <img
                  :src="
                    chats.sender.uuid === detailData.mentor.uuid
                      ? detailData.mentor.avatar ||
                        require(`@/assets/images/avatars/null-profile.webp`)
                      : detailData.user.avatar ||
                        require(`@/assets/images/avatars/null-profile.webp`)
                  "
                  class="chat-message-avatar"
                  alt="User Avatar"
                />
                <div class="message-content">
                  <div
                    :class="
                      chats.content.file.url !== null
                        ? 'message-content-group'
                        : ''
                    "
                  >
                    <p class="mb-0">
                      {{ chats.content.text }}
                    </p>
                    <template v-if="chats.content.file.url !== null">
                      <template
                        v-if="
                          chats.content.file.url
                            .split('.')
                            .pop()
                            .split(/\#|\?/)[0] == 'jpg' ||
                          chats.content.file.url
                            .split('.')
                            .pop()
                            .split(/\#|\?/)[0] == 'png' ||
                          chats.content.file.url
                            .split('.')
                            .pop()
                            .split(/\#|\?/)[0] == 'jpeg'
                        "
                      >
                        <img
                          :src="chats.content.file.url"
                          :alt="chats.content.file.name"
                          class="message-content-file"
                          @click="$bvModal.show(chats.content.file.url)"
                        />

                        <b-modal
                          :id="chats.content.file.url"
                          centered
                          class="text-center"
                          size="lg"
                          hide-footer
                        >
                          <div class="text-center">
                            <img
                              :src="chats.content.file.url"
                              class="img-fluid"
                              :alt="chats.content.file.name"
                            />
                          </div>
                        </b-modal>
                      </template>

                      <a
                        v-else
                        :href="chats.content.file.url"
                        class="rounded d-flex align-items-center text-white"
                        style="
                          gap: 12px;
                          background: rgb(19 20 19 / 0.1);
                          padding: 12px;
                        "
                        target="_blank"
                      >
                        <img
                          src="@/assets/images/icons/csv.svg"
                          class="file-icon bg-white rounded"
                          :alt="chats.content.file.name"
                          v-if="
                            chats.content.file.url
                              .split('.')
                              .pop()
                              .split(/\#|\?/)[0] == 'csv'
                          "
                        />
                        <img
                          src="@/assets/images/icons/pdf.svg"
                          class="file-icon bg-white rounded"
                          :alt="chats.content.file.name"
                          v-if="
                            chats.content.file.url
                              .split('.')
                              .pop()
                              .split(/\#|\?/)[0] == 'pdf'
                          "
                        />
                        <img
                          src="@/assets/images/icons/docx.svg"
                          class="file-icon bg-white rounded"
                          :alt="chats.content.file.name"
                          v-if="
                            chats.content.file.url
                              .split('.')
                              .pop()
                              .split(/\#|\?/)[0] == 'docx'
                          "
                        />
                        <img
                          src="@/assets/images/icons/doc.svg"
                          class="file-icon bg-white rounded"
                          :alt="chats.content.file.name"
                          v-if="
                            chats.content.file.url
                              .split('.')
                              .pop()
                              .split(/\#|\?/)[0] == 'doc'
                          "
                        />
                        <img
                          src="@/assets/images/icons/xsl.svg"
                          class="file-icon bg-white rounded"
                          :alt="chats.content.file.name"
                          v-if="
                            chats.content.file.url
                              .split('.')
                              .pop()
                              .split(/\#|\?/)[0] == 'xls' ||
                            chats.content.file.url
                              .split('.')
                              .pop()
                              .split(/\#|\?/)[0] == 'xlsx'
                          "
                        />
                        <div class="d-flex flex-column">
                          <div
                            class="d-flex align-items-center"
                            style="font-weight: 700; font-size: 12px"
                          >
                            <p class="message-content-file-name">
                              {{ chats.content.file.name }}
                            </p>
                            <p class="mb-0">
                              .{{
                                chats.content.file.name
                                  .split(".")
                                  .pop()
                                  .split(/\#|\?/)[0]
                              }}
                            </p>
                          </div>
                          <p class="mb-0" style="font-size: 8px">
                            {{ getFileSizeString(chats.content.file.size) }}
                          </p>
                        </div>
                      </a>
                    </template>
                  </div>
                  <div
                    class="message-content-time"
                    :class="
                      chats.sender.uuid === detailData.mentor.uuid
                        ? 'chat-user-time'
                        : ''
                    "
                  >
                    {{ moment(chats.created_at).format("HH:mm") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="reminder-countdown"
            :class="!showCountdown || counter <= 5 ? 'd-none' : 'd-block'"
            v-if="counter <= 300"
          >
            Sesi Express Mentorship kamu hanya tinggal 5 menit lagi. Gunakan
            waktumu sebaik mungkin!
            <div
              class="reminder-countdown-close"
              @click="showCountdown = false"
            >
              <feather-icon icon="XIcon" class="custom-class" />
            </div>
          </div>
          <div
            v-if="counter <= 0 || detailData.status === 'finished'"
            class="d-flex flex-column"
            style="padding: 20px; gap: 12px"
          >
            <button class="btn btn-primary w-100" @click="handleBackButton()">
              Selesai
            </button>
            <a
              href="#"
              @click.prevent="downloadFile"
              class="btn btn-success w-100"
            >
              Download Percakapan
            </a>
          </div>
          <div
            v-else-if="detailData.status === 'incoming'"
            style="padding: 20px"
          >
            <button class="btn btn-primary w-100" @click="handleBackButton()">
              Sesi belum dimulai
            </button>
          </div>
          <div
            class="input-group border-top"
            style="gap: 10px; padding: 20px"
            v-else
          >
            <div class="w-100" v-if="file_url !== null">
              <img
                :src="file_url"
                class="image_preview"
                :alt="file_name"
                v-if="
                  file_type === 'jpg' ||
                  file_type === 'png' ||
                  file_type === 'jpeg'
                "
              />
              <div
                class="d-flex"
                style="gap: 10px"
                v-if="
                  file_type === 'pdf' ||
                  file_type === 'csv' ||
                  file_type ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  file_type === 'application/msword' ||
                  file_type === 'application/vnd.ms-excel' ||
                  file_type ===
                    'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                "
              >
                <img
                  src="@/assets/images/icons/pdf.svg"
                  class="file-icon"
                  v-if="file_type === 'pdf'"
                />
                <img
                  src="@/assets/images/icons/csv.svg"
                  class="file-icon"
                  v-if="file_type === 'csv'"
                />
                <img
                  src="@/assets/images/icons/docx.svg"
                  class="file-icon"
                  v-if="
                    file_type ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  "
                />
                <img
                  src="@/assets/images/icons/doc.svg"
                  class="file-icon"
                  v-if="file_type === 'application/msword'"
                />
                <img
                  src="@/assets/images/icons/xsl.svg"
                  class="file-icon"
                  v-if="
                    file_type === 'application/vnd.ms-excel' ||
                    file_type ===
                      'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  "
                />
                <div class="d-flex flex-column">
                  <h5>{{ file_name }}</h5>
                  <p>{{ getFileSizeString(file_size) }}</p>
                </div>
              </div>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Type your message here..."
              v-model="newMessage"
              @keyup.enter="sendMessage(newMessage)"
            />
            <label
              class="input-group-text btn btn-primary file-input-label"
              for="fileInput"
            >
              <feather-icon icon="PlusIcon" />
            </label>
            <input
              type="file"
              class="file-input"
              id="fileInput"
              @change="inputFile($event)"
              accept="image/png, image/jpeg, image/jpg, .csv,application/pdf,application/msword,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document "
            />
            <button
              class="btn btn-primary"
              @click="sendMessage(newMessage)"
              :disabled="newMessage === '' && file_url === null"
            >
              <feather-icon icon="SendIcon" />
            </button>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import io from "socket.io-client";
import { successNotification, errorNotification } from "@/auth/utils";
import InfiniteLoading from "vue-infinite-loading";
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    vSelect,
    InfiniteLoading,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      moment,
      isLoading: false,
      currentPage: 1,
      chatData: [],
      detailData: {},
      socket: null,
      isDesktop: false,
      newMessage: "",
      newReport: "",
      userData: JSON.parse(localStorage.getItem("userData")),
      counter: 301,
      file_url: null,
      file_name: null,
      file_size: null,
      fileData: null,
      formPayload: {
        file: null,
      },
      showCountdown: true,
      file_type: "",
    };
  },

  created() {
    this.socket = io(process.env.API_BASE_URL, {
      auth: {
        token: "Bearer " + localStorage.getItem("accessToken"),
      },
      query: {
        auto_join: true,
        unique_uuid: this.$route.params.uuid,
        type: "mentorship-private-chat",
      },
    });

    this.socket.on("mentorship-listen-message", (message) => {
      this.chatData.push(message);
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    });

    this.socket.on("mentorship-listen-conversation-timer", (response) => {
      this.counter = response.remaining_seconds;
    });

    this.loadDetails();
  },

  mounted() {
    this.startConversation();

    this.isDesktop = window.innerWidth >= 992;

    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    async downloadFile() {
      const params = this.$route.params.uuid;
      try {
        const response = await this.$http.get(
          `/api/v1/mentor/mentorship/booked-schedules/${params}/download-message-history`,
          { responseType: "blob" }
        );
        const blob = new Blob([response.data], { type: "application/txt" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.detailData.user.name}-${this.detailData.topic.title}-${this.detailData.date}-${this.detailData.start_time}-${this.detailData.end_time}-chat-history.txt`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Failed to download file:", error);
      }
    },
    handleResize() {
      this.isDesktop = window.innerWidth >= 992;
    },
    getFileSizeString(bytes, decimals = 0) {
      const suffixes = ["B", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "0" + suffixes[0];
      var i = Math.floor(Math.log(bytes) / Math.log(1024));
      return (bytes / Math.pow(1024, i)).toFixed(decimals) + suffixes[i];
    },
    handleBackButton() {
      history.back();
    },
    loadDetails() {
      const params = this.$route.params.uuid;
      this.$http
        .get(`/api/v1/mentor/mentorship/booked-schedules/${params}`)
        .then((response) => {
          this.detailData = response.data.data;
        });
    },

    getData($state) {
      let route = this.$route.params.uuid;
      this.$http
        .get(`/api/v1/mentor/mentorship/booked-schedules/${route}/message`, {
          params: {
            page: this.currentPage,
          },
        })
        .then(({ data }) => {
          if (Array.isArray(data.data.items)) {
            if (data.data.items.length) {
              this.currentPage += 1;
              this.chatData.unshift(...data.data.items.reverse());
              if ($state) {
                $state.loaded();
              }
            } else {
              if ($state) {
                $state.complete();
              }
            }
          } else {
            console.error(
              "Received non-array items from server:",
              data.data.items
            );
          }
        });
    },

    async sendMessage() {
      const trimmedMessage = this.newMessage.trim();
      if (!trimmedMessage && !this.file_url) {
        return;
      }

      try {
        if (this.file_url) {
          const route = this.$route.params.uuid;
          const payload = new FormData();
          payload.append("file", this.formPayload.file);
          const response = await this.$http.post(
            `/api/v1/mentor/mentorship/booked-schedules/${route}/upload-file`,
            payload,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          this.fileData = response.data.data;
        }

        this.socket.emit(
          "mentorship-send-message",
          {
            uuid: this.$route.params.uuid,
            is_mentor: true,
            content_text: this.newMessage,
            content_file_url: this.fileData,
            content_file_size: this.file_size,
            content_file_name: this.file_name,
          },
          (data) => {}
        );

        this.newMessage = "";
        this.file_url = null;
        this.fileData = null;

        this.$nextTick(() => {
          this.scrollToBottom();
        });
      } catch (error) {
        if (error.response && error.response.data.meta.messages) {
          errorNotification(this, "Ooops!", error.response.data.meta.messages);
        }
      }
    },

    sendReport() {
      this.isLoading = true;
      this.socket.emit(
        "mentorship-submit-report-conversation",
        {
          uuid: this.$route.params.uuid,
          is_mentor: true,
          reason: this.newReport,
        },
        (data) => {
          if (data) {
            this.isLoading = false;
            this.$bvModal.hide("form-banner");
            this.$router.push({ name: "mentorship-session" });
          }
        }
      );
    },

    inputFile(event) {
      this.formPayload.file = event.target.files[0];
      this.showFile(event.target.files[0]);
    },
    showFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.file_url = e.target.result;
        this.file_name = file.name;
        this.file_size = file.size;
        var str = e.target.result;
        var startIndex = str.indexOf("/") + 1;
        var endIndex = str.indexOf(";");
        var imageType = str.substring(startIndex, endIndex);
        this.file_type = imageType;
      };
      reader.readAsDataURL(file);
    },

    scrollToBottom() {
      const container = this.$refs.messagesContainer;
      container.scrollTop = container.scrollHeight;
    },

    startConversation() {
      this.socket.emit(
        "mentorship-start-conversation",
        {
          uuid: this.$route.params.uuid,
          is_mentor: true,
        },
        (data) => {
          // This callback function will be executed when the server responds
          console.log("Received countdown data:", data);
          // Here you can handle the received countdown data, such as updating your UI
        }
      );
    },
  },

  beforeDestroy() {
    this.socket.disconnect();
    // Remove the event listener when the component is destroyed
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
$color_1: #ffff;
$background-color_1: #fff;
$background-color_2: #a2a6ba;
$background-color_3: #ea359f;

.chat-container {
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  background-color: $background-color_1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.chat-header {
  display: flex;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 2px -2px gray;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }
}
.chat-messages-box {
  min-height: 300px;
  max-height: 600px;
  overflow-y: auto;
  background: #f8f7fa;
  padding: 20px;
}
.chat-message {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  .chat-message-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .message-content {
    background-color: $background-color_2;
    padding: 10px;
    border-radius: 10px;
    color: $color_1;

    .message-content-time {
      margin-top: 4px;
      display: flex;
      font-size: 8px;
    }
    .message-content-time.chat-user-time {
      margin-top: 4px;
      display: flex;
      justify-content: end;
      font-size: 8px;
    }
    .message-content-group {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
    .message-content-file {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 10px;
    }
    .message-content-file-name {
      margin-bottom: 0;
      white-space: nowrap;
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.chat-message.chat-user {
  flex-direction: row-reverse;
  .message-content {
    background-color: $background-color_3;
    color: $color_1;
  }
}
.reminder-countdown {
  position: relative;
  padding: 12px 20px;
  background: #f15a22;
  color: $color_1;
  .reminder-countdown-close {
    position: absolute;
    right: 20px;
    top: 12px;
  }
}
.file-input {
  display: none;
}

.file-icon {
  width: 40px;
  height: 40px;
}

.image_preview {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 20px;
}
.btn-file {
  margin-top: 10px;
}
</style>
