var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"align-items-center justify-content-center"},[_c('b-col',{attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"chat-container"},[_c('div',{staticClass:"chat-header border-bottom justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":_vm.detailData.user && _vm.detailData.user.avatar
                  ? _vm.detailData.user.avatar
                  : require("@/assets/images/avatars/null-profile.webp"),"alt":"User Avatar"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('h4',[_vm._v(_vm._s(_vm.detailData.user ? _vm.detailData.user.name : "-"))]),_c('p',{staticClass:"mb-0"},[_vm._v(" Topik: "),_c('b',[_vm._v(_vm._s(_vm.detailData.topic ? _vm.detailData.topic.title : "-"))])])])]),(_vm.detailData.status === 'ongoing')?_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.form-report",modifiers:{"form-report":true}}],staticClass:"text-primary"},[_c('feather-icon',{staticClass:"custom-class",attrs:{"icon":"FlagIcon","size":"1.5x"}})],1):_vm._e(),_c('b-modal',{staticClass:"text-center",attrs:{"id":"form-report","centered":!_vm.isDesktop,"no-close-on-backdrop":"","size":"sm","title":"Report"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.sendReport()}}},[_vm._v(" Submit ")])]}}])},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"group"}},[_vm._v("Berikan alasan mengapa kamu mereport ?")]),_c('v-select',{attrs:{"id":"group","label":"description","options":[
                  'Konten merupakan spam',
                  'Konten mengandung unsur pornografi',
                  'Konten mengandung kebencian atau SARA',
                  'Perundungan (bullying) atau penghinaan',
                  'Konten merupakan informasi palsu (hoax)',
                  'Konten mengandung kekerasan',
                  'Konten merupakan penipuan',
                  'Konten mengandung pelanggaran HAKI',
                  'Konten menjual barang ilegal atau dilarang pemerintah',
                  'Konten mengandung unsur menyakiti diri sendiri',
                  'Konten mengandung unsur gangguan perilaku makan (eating disorder)',
                  'Konten mengandung unsur obat terlarang',
                  'Kebocoran data pribadi saya atau orang lain',
                  'Lainnya' ],"placeholder":"-- Pilih --","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newReport),callback:function ($$v) {_vm.newReport=$$v},expression:"newReport"}})],1)])],1),_c('div',{ref:"messagesContainer",staticClass:"chat-messages-box"},[_c('infinite-loading',{attrs:{"direction":"top"},on:{"infinite":_vm.getData}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})]),_vm._l((_vm.chatData),function(chats){return _c('div',{key:chats.uuid},[_c('div',{staticClass:"chat-message",class:chats.sender.uuid === _vm.detailData.mentor.uuid
                  ? 'chat-user'
                  : ''},[_c('img',{staticClass:"chat-message-avatar",attrs:{"src":chats.sender.uuid === _vm.detailData.mentor.uuid
                    ? _vm.detailData.mentor.avatar ||
                      require("@/assets/images/avatars/null-profile.webp")
                    : _vm.detailData.user.avatar ||
                      require("@/assets/images/avatars/null-profile.webp"),"alt":"User Avatar"}}),_c('div',{staticClass:"message-content"},[_c('div',{class:chats.content.file.url !== null
                      ? 'message-content-group'
                      : ''},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(chats.content.text)+" ")]),(chats.content.file.url !== null)?[(
                        chats.content.file.url
                          .split('.')
                          .pop()
                          .split(/\#|\?/)[0] == 'jpg' ||
                        chats.content.file.url
                          .split('.')
                          .pop()
                          .split(/\#|\?/)[0] == 'png' ||
                        chats.content.file.url
                          .split('.')
                          .pop()
                          .split(/\#|\?/)[0] == 'jpeg'
                      )?[_c('img',{staticClass:"message-content-file",attrs:{"src":chats.content.file.url,"alt":chats.content.file.name},on:{"click":function($event){return _vm.$bvModal.show(chats.content.file.url)}}}),_c('b-modal',{staticClass:"text-center",attrs:{"id":chats.content.file.url,"centered":"","size":"lg","hide-footer":""}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":chats.content.file.url,"alt":chats.content.file.name}})])])]:_c('a',{staticClass:"rounded d-flex align-items-center text-white",staticStyle:{"gap":"12px","background":"rgb(19 20 19 / 0.1)","padding":"12px"},attrs:{"href":chats.content.file.url,"target":"_blank"}},[(
                          chats.content.file.url
                            .split('.')
                            .pop()
                            .split(/\#|\?/)[0] == 'csv'
                        )?_c('img',{staticClass:"file-icon bg-white rounded",attrs:{"src":require("@/assets/images/icons/csv.svg"),"alt":chats.content.file.name}}):_vm._e(),(
                          chats.content.file.url
                            .split('.')
                            .pop()
                            .split(/\#|\?/)[0] == 'pdf'
                        )?_c('img',{staticClass:"file-icon bg-white rounded",attrs:{"src":require("@/assets/images/icons/pdf.svg"),"alt":chats.content.file.name}}):_vm._e(),(
                          chats.content.file.url
                            .split('.')
                            .pop()
                            .split(/\#|\?/)[0] == 'docx'
                        )?_c('img',{staticClass:"file-icon bg-white rounded",attrs:{"src":require("@/assets/images/icons/docx.svg"),"alt":chats.content.file.name}}):_vm._e(),(
                          chats.content.file.url
                            .split('.')
                            .pop()
                            .split(/\#|\?/)[0] == 'doc'
                        )?_c('img',{staticClass:"file-icon bg-white rounded",attrs:{"src":require("@/assets/images/icons/doc.svg"),"alt":chats.content.file.name}}):_vm._e(),(
                          chats.content.file.url
                            .split('.')
                            .pop()
                            .split(/\#|\?/)[0] == 'xls' ||
                          chats.content.file.url
                            .split('.')
                            .pop()
                            .split(/\#|\?/)[0] == 'xlsx'
                        )?_c('img',{staticClass:"file-icon bg-white rounded",attrs:{"src":require("@/assets/images/icons/xsl.svg"),"alt":chats.content.file.name}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"font-weight":"700","font-size":"12px"}},[_c('p',{staticClass:"message-content-file-name"},[_vm._v(" "+_vm._s(chats.content.file.name)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" ."+_vm._s(chats.content.file.name .split(".") .pop() .split(/\#|\?/)[0])+" ")])]),_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"8px"}},[_vm._v(" "+_vm._s(_vm.getFileSizeString(chats.content.file.size))+" ")])])])]:_vm._e()],2),_c('div',{staticClass:"message-content-time",class:chats.sender.uuid === _vm.detailData.mentor.uuid
                      ? 'chat-user-time'
                      : ''},[_vm._v(" "+_vm._s(_vm.moment(chats.created_at).format("HH:mm"))+" ")])])])])})],2),(_vm.counter <= 300)?_c('div',{staticClass:"reminder-countdown",class:!_vm.showCountdown || _vm.counter <= 5 ? 'd-none' : 'd-block'},[_vm._v(" Sesi Express Mentorship kamu hanya tinggal 5 menit lagi. Gunakan waktumu sebaik mungkin! "),_c('div',{staticClass:"reminder-countdown-close",on:{"click":function($event){_vm.showCountdown = false}}},[_c('feather-icon',{staticClass:"custom-class",attrs:{"icon":"XIcon"}})],1)]):_vm._e(),(_vm.counter <= 0 || _vm.detailData.status === 'finished')?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"padding":"20px","gap":"12px"}},[_c('button',{staticClass:"btn btn-primary w-100",on:{"click":function($event){return _vm.handleBackButton()}}},[_vm._v(" Selesai ")]),_c('a',{staticClass:"btn btn-success w-100",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile.apply(null, arguments)}}},[_vm._v(" Download Percakapan ")])]):(_vm.detailData.status === 'incoming')?_c('div',{staticStyle:{"padding":"20px"}},[_c('button',{staticClass:"btn btn-primary w-100",on:{"click":function($event){return _vm.handleBackButton()}}},[_vm._v(" Sesi belum dimulai ")])]):_c('div',{staticClass:"input-group border-top",staticStyle:{"gap":"10px","padding":"20px"}},[(_vm.file_url !== null)?_c('div',{staticClass:"w-100"},[(
                _vm.file_type === 'jpg' ||
                _vm.file_type === 'png' ||
                _vm.file_type === 'jpeg'
              )?_c('img',{staticClass:"image_preview",attrs:{"src":_vm.file_url,"alt":_vm.file_name}}):_vm._e(),(
                _vm.file_type === 'pdf' ||
                _vm.file_type === 'csv' ||
                _vm.file_type ===
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                _vm.file_type === 'application/msword' ||
                _vm.file_type === 'application/vnd.ms-excel' ||
                _vm.file_type ===
                  'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              )?_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[(_vm.file_type === 'pdf')?_c('img',{staticClass:"file-icon",attrs:{"src":require("@/assets/images/icons/pdf.svg")}}):_vm._e(),(_vm.file_type === 'csv')?_c('img',{staticClass:"file-icon",attrs:{"src":require("@/assets/images/icons/csv.svg")}}):_vm._e(),(
                  _vm.file_type ===
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                )?_c('img',{staticClass:"file-icon",attrs:{"src":require("@/assets/images/icons/docx.svg")}}):_vm._e(),(_vm.file_type === 'application/msword')?_c('img',{staticClass:"file-icon",attrs:{"src":require("@/assets/images/icons/doc.svg")}}):_vm._e(),(
                  _vm.file_type === 'application/vnd.ms-excel' ||
                  _vm.file_type ===
                    'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                )?_c('img',{staticClass:"file-icon",attrs:{"src":require("@/assets/images/icons/xsl.svg")}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('h5',[_vm._v(_vm._s(_vm.file_name))]),_c('p',[_vm._v(_vm._s(_vm.getFileSizeString(_vm.file_size)))])])]):_vm._e()]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMessage),expression:"newMessage"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Type your message here..."},domProps:{"value":(_vm.newMessage)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage(_vm.newMessage)},"input":function($event){if($event.target.composing){ return; }_vm.newMessage=$event.target.value}}}),_c('label',{staticClass:"input-group-text btn btn-primary file-input-label",attrs:{"for":"fileInput"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1),_c('input',{staticClass:"file-input",attrs:{"type":"file","id":"fileInput","accept":"image/png, image/jpeg, image/jpg, .csv,application/pdf,application/msword,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document "},on:{"change":function($event){return _vm.inputFile($event)}}}),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.newMessage === '' && _vm.file_url === null},on:{"click":function($event){return _vm.sendMessage(_vm.newMessage)}}},[_c('feather-icon',{attrs:{"icon":"SendIcon"}})],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }